import * as React from 'react';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import './RescheduleSession.scss';
import Typography from '../../components/Typography';
import { IRescheduleSessions } from './types';
import {
  AvailableSessionDurations,
  useProviderOpenSlots,
  useproviderClientDataQuery,
} from '../../../utilities/hooks/fetchHooks/provider';
import RadioGroup from '../../components/RadioGroup';
import SessionSelectButton from '../SessionSelectButton';
import ClientDetailsCard from '../ClientDetailsCard';
import DatePicker from '../../components/DatePickerV2';
import SelectList from '../../components/SelectList';
import Button from '../../components/Button';
import Loader from '../../components/Loader';
import { ProviderCancellationReasons } from '../../../shared/constant/CancellationReasons';
import { getProviderRole, getProviderRolesOptions } from '../../../utilities/common/Provider';
import { getCurrentLocale } from '../../../i18n';

function RescheduleSession({
  headerText,
  sessionData,
  cancellationReasonCode,
  onConfirm,
}: IRescheduleSessions) {
  const { t } = useTranslation();

  const { userId, providerId, providerRole, meetingId } = sessionData;

  const [parentDimensions, setParentDimensions] = React.useState<DOMRect>();
  // const [durationList, setDurationList] = React.useState<string[]>([]);
  // const [isClinicalunlimited, setIsClinicalunlimited] = React.useState(false);
  const [selectedSessionDuration, setSelectedSessionDuration] =
    React.useState<AvailableSessionDurations>('900');
  const [selectedDuration, setSelectedDuration] = React.useState('');
  const [selectedDate, setSelectedDate] = React.useState<string>('');
  const [selectedSlot, setSelectedSlot] = React.useState<string>('');

  const { data: clientDetails, isLoading } = useproviderClientDataQuery(
    Number(userId),
    providerRole,
  );
  const providerRolesOptions = getProviderRolesOptions();

  //* Query to get selected client slot
  const { data: providerOpenSlots } = useProviderOpenSlots(
    providerId,
    providerRole as string,
    userId,
    cancellationReasonCode ===
      ProviderCancellationReasons.CANCEL_REASON_PROVIDER_CHANGE_DURATION
      ? meetingId
      : undefined,
  );
  const availableDuration = clientDetails?.duration
    ?.map((time: string) => parseInt(time, 10))
    .sort((a: number, b: number) => a - b);

  const currentMeetingDuration = moment
    .unix(Number(sessionData.scheduledEndTime))
    .diff(moment.unix(Number(sessionData.scheduledStartTime)), 'second');

  const availableSessionDurationList = React.useMemo(() => {
    if (
      cancellationReasonCode ===
      ProviderCancellationReasons.CANCEL_REASON_PROVIDER_CHANGE_DURATION
    ) {
      return availableDuration?.filter((item) => item > currentMeetingDuration);
    }
    return availableDuration?.filter((item)=> item === currentMeetingDuration);
  }, [availableDuration, currentMeetingDuration]);

  // todo remove this section after UAT
  // React.useEffect(() => {
  //   if (
  //     holisticRoles.includes(
  //       clientDetails?.providerMapping?.providerRole || '',
  //     ) &&
  //     providerOpenSlots
  //   ) {
  //     const availableDurationList = Object.keys(providerOpenSlots).filter(
  //       (item) => item === '1800',
  //     );
  //     setDurationList(availableDurationList);
  //     return;
  //   }
  //   if (isClinicalunlimited === false && providerOpenSlots) {
  //     const availableDurationList = Object.keys(providerOpenSlots).filter(
  //       (item) => item !== '5400',
  //     );
  //     setDurationList(availableDurationList);
  //   } else if (providerOpenSlots) {
  //     setDurationList(Object.keys(providerOpenSlots));
  //   }
  // }, [isClinicalunlimited, providerOpenSlots, clientDetails]);

  const setDurationFun = (item: string) => {
    setSelectedSessionDuration(item as AvailableSessionDurations);
    setSelectedDuration(item);
  };

  // * Function to handle Select Date
  const handleSelectDate = (date: string) => {
    setSelectedDate(date);
    setSelectedSlot('');
  };

  //* Function to getAvailable Date Slots
  const getAvailableSlotsDates = () => {
    if (
      providerOpenSlots &&
      selectedSessionDuration &&
      providerOpenSlots[selectedSessionDuration]
    ) {
      return providerOpenSlots[selectedSessionDuration].map(
        (slot) => slot.displayDate,
      );
    }
    return [];
  };

  //* Function to getAvailable time Slots
  const getAvailableSlots = () => {
    let slots: { label: string; value: string }[] = [];
    if (
      providerOpenSlots &&
      selectedSessionDuration &&
      providerOpenSlots[selectedSessionDuration] &&
      selectedDate
    ) {
      const availableSlots = providerOpenSlots[selectedSessionDuration];
      slots = availableSlots
        .filter((slot) => slot.displayDate === selectedDate)
        .map(({ displayTime, startTimeUtc }) => ({
          // ja follows 24 hrs format
          label: getCurrentLocale().includes('ja') ? moment(startTimeUtc *  1000).format('HH:mm') : displayTime,
          value: startTimeUtc?.toString(),
        })); 
    }

    return slots;
  };

  const slots = React.useMemo(
    () => getAvailableSlots(),
    [selectedSessionDuration, selectedDate],
  );

  // todo remove after UAT
  // React.useEffect(() => {
  //   if (providerOpenSlots) {
  //     const clinicalUnlimited =
  //       clientDetails?.credits.tier === '1';
  //     setIsClinicalunlimited(clinicalUnlimited);
  //   }
  // }, [providerOpenSlots, clientDetails]);

  //* Function to get parent dimension
  const parentMeasuredRef = React.useCallback((node: any) => {
    if (node !== null) {
      setParentDimensions(node.getBoundingClientRect());
    }
  }, []);

  React.useEffect(() => {
    if (
      cancellationReasonCode !==
      ProviderCancellationReasons.CANCEL_REASON_PROVIDER_CHANGE_DURATION
    ) {
      const previousDuration =
        Number(sessionData.scheduledEndTime) -
        Number(sessionData.scheduledStartTime);

      setSelectedDuration(previousDuration.toString());
      setSelectedSessionDuration(
        previousDuration.toString() as AvailableSessionDurations,
      );
    }
  }, [sessionData, cancellationReasonCode]);

  const sessionType = React.useMemo(
    () =>
      providerRolesOptions.filter(
        (item) =>
          item.value.toLowerCase() ===
          getProviderRole(clientDetails?.providerMapping?.providerRole),
      ),
    [clientDetails],
  );


  return (
    <article className="reschedule-wrapper" ref={parentMeasuredRef}>
      {isLoading && <Loader useFullPage={false} dimension={parentDimensions} />}

      <section className="reschedule-wrapper-header">
        <Typography size={18} weight="500" color="white">
          {headerText}
        </Typography>
      </section>
      <section className="reschedule-client-details">
        <Typography size={14} weight="500" color="swamp" textAlign="left">
          {t('clientDetails')}
        </Typography>
        {clientDetails && <ClientDetailsCard clientData={clientDetails} />}
      </section>
      <section className="session-category">
        <Typography size={14} weight="500" color="swamp" textAlign="left">
          {t('sessionType')}
        </Typography>
        <RadioGroup
          name="languages"
          values={sessionType}
          onChange={() => {}}
          selectedValue={sessionType?.[0]?.value}
          customCss
          duration
        />
      </section>
      <section className="session-duration">
        <Typography size={14} weight="500" color="swamp" textAlign="left">
          {t('sessionDuration')}
        </Typography>
        <Typography
          size={12}
          weight="400"
          withColor="rgba(26, 38, 49, 0.48)"
          textAlign="left"
        >
          {t('CREDITS_USED_NOTIF')}
        </Typography>
        <section className="duration-category">
          {availableSessionDurationList?.map((item) => {
            const buttonName = item / 60;
            return (
              <SessionSelectButton
                key={item}
                buttonName={`${buttonName?.toString()} ${t('minutes')}`}
                onClick={() => setDurationFun(item.toString())}
                selectedDuration={selectedDuration}
                currentElement={item.toString()}
                disabled={
                  cancellationReasonCode !==
                  ProviderCancellationReasons.CANCEL_REASON_PROVIDER_CHANGE_DURATION
                }
              />
            );
          })}
        </section>
      </section>
      <section className="slot-selection">
        <Typography size={14} weight="500" color="swamp" textAlign="left">
          {t('sessionDate')}
        </Typography>
        <DatePicker
          onSelect={(date) => handleSelectDate(date)}
          enabledDates={getAvailableSlotsDates()}
        />
      </section>
      {slots?.length !== 0 && (
        <section className="time-slots ">
          <Typography size={14} weight="500" color="swamp" textAlign="left">
            {t('selectStartTime')}
          </Typography>
          <section className="fix-time-slot-container">
            <SelectList
              options={slots}
              selectedValue={selectedSlot}
              onClick={(newSlot) => setSelectedSlot(newSlot)}
              emptyLabel={{
                show: Boolean(selectedDate) && !slots.length,
                label: 'No available slots, please try a different date.',
              }}
            />
          </section>
        </section>
      )}
      <section className="buttton-wrapper">
        <Button
          label={t('confirmButton')}
          onClick={() =>
            onConfirm(
              {
                scheduledStartTime: selectedSlot,
                duration: selectedSessionDuration,
                role: providerRole,
                reason: cancellationReasonCode,
              },
              sessionData.meetingId,
            )
          }
          variant="blue"
          width="100%"
          height="48px"
          minHeight="48px"
          disabled={!selectedSlot}
        />
      </section>
    </article>
  );
}

export default RescheduleSession;
