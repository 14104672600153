import React, { useState } from 'react';
import './ProfilePopup.scss';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import lodash from 'lodash';
import Typography from '../../components/Typography';
import intellectVerifiedIcon from '../../../assets/intellect-verified.png';
import soundWaveIcon from '../../../assets/sound-wave.svg';
import blueBadgeIcon from '../../../assets/badge-blue.png';
import Button from '../../components/Button';
import { getImageUri } from '../../../utilities/common/Display';
import useAuth from '../../../utilities/hooks/useAuth';
import {
  getProviderProfileData,
  getProviderSessions,
} from '../../../shared/redux/actions/provider';
import { ProviderAttributes } from '../../../shared/constant/Common';
import { useFetch } from '../../../utilities/hooks/useFetch';
import { IProviderData } from '../../../shared/types/response/provider';
import Loader from '../../components/Loader';
import useOutsideClickHandler from '../../../utilities/hooks/useOutsideClickHandler/index';
import getProfileTranslations from '../../../utilities/common/Profile';

interface IProfilePopupProps {
  setShowProfilePopup: (value: boolean) => void;
}

function ProfilePopup({ setShowProfilePopup }: IProfilePopupProps) {
  // React Hooks
  const dispatch = useDispatch();
  const parentRef = React.useRef<HTMLDivElement>(null);
  // Custom Hooks
  const { t } = useTranslation();
  const { invalidateUser, user } = useAuth();
  // local State
  const [parentDimensions, setParentDimensions] = useState<DOMRect>();
  // React Query
  const { data: providerData, isLoading } = useFetch<IProviderData>(
    ['provider-profile', user.id],
    () => dispatch(getProviderProfileData(user.id)),
  );
  const {
    profileImage = '',
    languages = [],
  } = providerData || {};
  const {
    name, bio, attributes,
  } = getProfileTranslations(providerData as IProviderData);

  // Functions
  const onLogoutClick = () => {
    invalidateUser();
  };

  //* Function to get the parent dimensions
  const parentMeasuredRef = React.useCallback((node:any) => {
    if (node !== null) {
      setParentDimensions(node.getBoundingClientRect());
    }
  }, []);

  //* Function to detect outsideclick of popup
  useOutsideClickHandler(parentRef, () => setShowProfilePopup(false));

  // Component Life Cycle
  React.useEffect(() => {
    if (lodash.isEmpty(providerData)) {
      dispatch(getProviderSessions());
    }
  }, []);

  return (
    <div className='profile-container' ref={parentMeasuredRef}>
      {isLoading ? (
        <Loader useFullPage={false} dimension={parentDimensions} />
      ) : (
        <div ref={parentRef}>
          {' '}
          <div className='profile-header'>
            <Typography size={24} weight='600'>
              {t('profile')}
            </Typography>
          </div>
          <div className='profile-metadata'>
            <div className='left-content'>
              <div className='profile-image'>
                <img src={getImageUri(profileImage)} alt={name} />
              </div>
              <div className='profile-summary'>
                <Typography size={20} weight='600'>
                  {name}
                </Typography>
                <Typography
                  size={14}
                  weight='500'
                  withColor='rgba(26, 38, 49, 0.4)'
                >
                  {t('intellectCoach')}
                </Typography>
                <div className='languages'>
                  <img src={soundWaveIcon} alt='languages' />
                  <Typography size={14} weight='500' color='solarized'>
                    {languages &&
                      languages.length &&
                      languages.map(
                        (lang: any, index: number) =>
                          `${lang.name}${
                            index !== languages.length - 1 ? ',' : ''
                          } `,
                      )}
                  </Typography>
                </div>
              </div>
            </div>
            <div className='right-content'>
              <img src={intellectVerifiedIcon} alt='Intellect verified' />
            </div>
          </div>
          <div className='profile-item'>
            <Typography weight='700' size={18}>
              {t('aboutMe')}
            </Typography>
            <Typography weight='500' size={14} color='solarized'>
              {bio}
            </Typography>
          </div>
          {!!attributes.filter(a => a.type === ProviderAttributes.PROFESSIONAL_BACKGROUND).length && (
            <div className='profile-item'>
              <Typography weight='700' size={18}>
                {t('professionalBackground')}
              </Typography>
              {/* // TODO check enum types for attribute types and group them beforehand instead of filter */}
              {attributes.filter(a => a.type === ProviderAttributes.PROFESSIONAL_BACKGROUND).map((item) => (
                <div className='with-badge' key={item.id}>
                  <img src={blueBadgeIcon} alt='badge' />
                  <Typography size={14} weight='500'>
                    {item.name}
                  </Typography>
                </div>
              ))}
            </div>
          )}
          {!!attributes.filter(a => a.type === ProviderAttributes.AREA_OF_SPECIALTY).length && (
            <div className='profile-item'>
              <Typography weight='700' size={18}>
                {t('areaOfSpecialty')}
              </Typography>
              <Typography weight='500' size={14} color='solarized'>
                {attributes.filter(a => a.type === ProviderAttributes.AREA_OF_SPECIALTY).map(
                  (item) =>
                    `${item.name}, `,
                )}
              </Typography>
            </div>
          )}
          {!!attributes.filter(a => a.type === ProviderAttributes.MY_APPROACH).length && (
            <div className='profile-item'>
              <Typography weight='700' size={18}>
                {t('myApproach')}
              </Typography>
              <Typography weight='500' size={14} color='solarized'>
                {attributes.filter(a => a.type === ProviderAttributes.MY_APPROACH)
                  .filter(
                    (attr) => attr.type === ProviderAttributes.MY_APPROACH,
                  )
                  .map(
                    (item) =>
                      `${item.name}, `,
                  )}
              </Typography>
            </div>
          )}
          <div className='profile-actions'>
            <Button
              label={t('logout')}
              variant='primary'
              width='40%'
              height='3rem'
              onClick={onLogoutClick}
            />
          </div>
        </div>
      )}
    </div>
  );
}

export default ProfilePopup;
