import * as React from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { connect } from 'react-redux';
// import TwilioRoom from './TwilioRoom';
// import DailyRoom from './DailyRoom';
import { useFetch } from '../../../utilities/hooks/useFetch';
import useToast from '../../../utilities/hooks/useToast';
import Loader from '../../components/Loader';
import VideoCallRoom from '../../screens/VideoCallRoom/VideoCallRoom';
import { VideoPlatformCodes } from '../../../shared/constant/App';
import {
  IMeetingInfo,
  IMeetingStatus,
} from '../../../shared/types/response/provider';
import { getDailyCoUrlFromRoomId } from '../../../utilities/common/Meeting';
import Header from './DailyRoom/components/Header/Header';
import { getMeetingDetails } from '../../../shared/service/API/meeting';

function VideoRoom({ meeting }: { meeting: IMeetingStatus }) {
  const toast = useToast();
  const navigate = useNavigate();

  const { channel, meetingId } = useParams();
  if (!channel || !meetingId || !(channel in VideoPlatformCodes)) {
    toast.show({ message: 'Invalid Meeting Id or Channel' });
    window.history.back();
    return null;
  }

  const { data, isLoading } = useFetch<IMeetingInfo>(
    [channel, meetingId, 'meetingDetailsVideo'],
    () => getMeetingDetails(meetingId, channel),
    {
      onError: (error: any) => {
        toast.show({ message: error?.message || 'Something went wrong' });
        navigate('/');
      },
      retry: false,
      refetchOnWindowFocus: false,
    },
  );
  // const rooms = {
  //   '0': <TwilioRoom />,
  //   '5': <DailyRoom />,
  //   default: <TwilioRoom />,
  // };

  // return rooms[channel as keyof typeof rooms] || rooms.default;
  console.log('data', data);
  const locationRef = React.useMemo(() => {
    if (channel === '5' && !isLoading) {
      return getDailyCoUrlFromRoomId(data?.meeting?.locationRef || '');
    }
    return data?.meeting?.locationRef;
  }, [data?.meeting?.locationRef, isLoading]);

  if (!isLoading && !!locationRef) {
    return (
      <>
        <Header meetingData={meeting?.data} />
        <VideoCallRoom
          channel={VideoPlatformCodes[channel as string]}
          locationRef={locationRef}
          token={data?.token}
          meeting={meeting}
          isFirstMeeting={!data?.completedMeeting}
        />
      </>
    );
  }
  return <Loader useFullPage />;
}

const mapStateToProps = (state: any) => ({ meeting: state.app.meeting });

export default connect(mapStateToProps)(VideoRoom);
